(function () {
  'use strict';

  /* @ngdoc object
   * @name events.vehicles.guests.edit
   * @description
   *
   */
  angular
    .module('events.vehicles.guests.edit', [
      'ui.router'
    ]);
}());
